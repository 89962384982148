import React, { useEffect, useContext } from "react"
import { navigate } from "gatsby"

import HeaderContext from "../context/header-context"
import Container from "../components/container"
import Slab from "../components/slab"
import LinkList from "../components/link-list"
import MotionWrapper from "../components/motion-wrapper"
import SEO from "../components/seo"

const MenuPage = ({ location }) => {
  const prevPath = location.state ? location.state.prevPath : "/"

  const { updateHeaderState } = useContext(HeaderContext)

  useEffect(() => {
    updateHeaderState({
      isHome: false,
      inverseHeaderColor: true,
      prevPath: prevPath,
    })
  }, [updateHeaderState, prevPath])

  useEffect(() => {
    const handleEsc = event => {
      if (event.keyCode === 27) {
        navigate(prevPath)
      }
    }
    window.addEventListener("keydown", handleEsc)

    return () => {
      window.removeEventListener("keydown", handleEsc)
    }
  }, [prevPath])

  const pages = [
    {
      title: "Home",
      slug: "/",
    },
    {
      title: "Serve people well",
      slug: "/serve-people-well",
    },
    {
      title: "Work",
      slug: "/made",
    },
    {
      title: "Specialisms",
      slug: "/does",
    },
    {
      title: "Blog",
      slug: "/blog",
    },
    {
      title: "Contact",
      slug: "/contact",
    },
  ]

  return (
    // <Layout inverseHeaderColor prevPath={prevPath} />
    <>
      <SEO description="Navigate the Mutual website" title="Menu" />
      <MotionWrapper>
        <Slab first bg="background" color="text">
          <Container>
            <LinkList links={pages} />
          </Container>
        </Slab>
      </MotionWrapper>
    </>
  )
}

export default MenuPage
